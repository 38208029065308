// extracted by mini-css-extract-plugin
export var background_images = "AreasInfo-module__background_images___jSZld";
export var body_background = "#f8f9fa";
export var card = "AreasInfo-module__card___JCOKk";
export var chip = "AreasInfo-module__chip___aIyAO";
export var container = "AreasInfo-module__container___RH-sR";
export var container_areas_info = "AreasInfo-module__container_areas_info___ux5T-";
export var container_areas_info__container_offer = "AreasInfo-module__container_areas_info__container_offer___t01F-";
export var container_areas_info__content = "AreasInfo-module__container_areas_info__content___4qCWI";
export var container_areas_info__content_offer = "AreasInfo-module__container_areas_info__content_offer___AEU1m";
export var container_areas_info__img = "AreasInfo-module__container_areas_info__img___i-0cm";
export var container_areas_info__main_title = "AreasInfo-module__container_areas_info__main_title___TXtJ5";
export var container_areas_info__subtitle = "AreasInfo-module__container_areas_info__subtitle___ilhqG";
export var container_areas_info__title = "AreasInfo-module__container_areas_info__title___0qGgI";
export var container_areas_info__title_agency = "AreasInfo-module__container_areas_info__title_agency___QXhdL";
export var content = "AreasInfo-module__content___k0DKH";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "AreasInfo-module__full_img___MU-In";
export var full_imgSmall = "AreasInfo-module__full_img--small___6N9fy";
export var gray_21 = "AreasInfo-module__gray_21___Be5FY";
export var image_container = "AreasInfo-module__image_container___pc+Bc";
export var image_item = "AreasInfo-module__image_item___CVJtq";
export var lg = "1200px";
export var logo = "AreasInfo-module__logo___Epy69";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "AreasInfo-module__primary___Gngf3";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "AreasInfo-module__title___v6N9s";
export var typography_h1 = "AreasInfo-module__typography_h1___jHAOe";
export var typography_h2 = "AreasInfo-module__typography_h2___jel07";
export var typography_h3 = "AreasInfo-module__typography_h3___XKmtV";
export var white = "#fff";
export var white_button = "AreasInfo-module__white_button___e8qfX";
export var xl = "1536px";
export var xxl = "2500px";