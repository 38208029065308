import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './AreasInfo.module.scss';

const AreasInfo = ({ intl: { formatMessage } }) => {
  const offers = [
    { id: 0, title: 'jobPool', text: 'weManage' },
    { id: 1, title: 'weAccompanyYou', text: 'weHelpYou' },
    { id: 2, title: 'orientation', text: 'ourOrientationServices' },
  ];
  return (
    <section className={styles.container_areas_info}>
      <h3 className={styles.container_areas_in}>
        {formatMessage({ id: 'doYouWantToBoostYourCareer' })}
      </h3>
      <h5 className="subtitle">{formatMessage({ id: 'joinUs' })}</h5>
      <div>
        <div className={styles.container_areas_info__content}>
          <StaticImage
            src="../../../../../../images/star.webp"
            alt="star"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={['auto', 'webp', 'avif']}
            width={20}
            height={21}
          />
          <h5 className={styles.container_areas_info__title_agency}>
            {formatMessage({ id: 'weAreTheAgency' })}
          </h5>
        </div>
        <div className={styles.container_areas_info__container_offer}>
          {offers.map((offer) => (
            <div
              key={offer.id}
              className={styles.container_areas_info__content_offer}
            >
              <StaticImage
                src="../../../../../../images/checked.webp"
                alt="checked"
                placeholder="blurred"
                loading="lazy"
                objectFit="contain"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
                className={styles.container_areas_info__img}
              />
              <div>
                <p className={styles.container_areas_info__title}>
                  {formatMessage({ id: offer.title })}
                </p>
                <small className={styles.container_areas_info__subtitle}>
                  {formatMessage({ id: offer.text })}
                </small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default injectIntl(AreasInfo);

AreasInfo.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
